<template>
  <h1>Regler og vilkår for bruk av området til Finstadbru Hundesport</h1>
  <h2>
    For at det skal fungere som et hyggelig sted for alle er vi nødt til å ha
    visse regler som det er viktig at alle respekterer.
  </h2>
  <ul>
    <li>
      Alle står ansvarlig for å følge de nasjonale smitteverntiltakene fra
      regjeringen, samt hva som gjelder i Aurskog-Høland kommune.
    </li>
    <hr />
    <li>
      All trening foregår på eget ansvar og Finstadbru hundesport har ikke
      ansvar for eventuelle skader. Eier har selv økonomisk ansvar for
      eventuelle skader og det må tas med den det gjelder der og da.
    </li>
    <hr />
    <li>
      Av hensyn til naboer og andre som eventuelt leier i samme tidsrom som deg:
      Hold støynivået nede. Det betyr at hunder ikke kan stå og bjeffe rundt
      banen.
    </li>
    <hr />
    <li>
      Hunder skal få lov til å være hunder, men graving i eller utenfor banen er
      ikke lov.
    </li>
    <hr />
    <li>
      Såfremt ikke annet er avtalt med eventuelle andre som disponerer området
      samtidig, skal hunden være i bånd når den ikke trener.
    </li>
    <hr />
    <li>Barn skal alltid komme ifølge med voksne.</li>
    <hr />
    <li>
      Hvis det oppstår konflikter må dette tas direkte med den det gjelder
      ettersom vi i Finstadbru hundesport ikke er til stede på område er det
      umulig for oss å oppklare hendelser vi ikke har sett.
    </li>
    <hr />
    <li>Bookingen er økonomisk bindende.</li>
    <hr />
    <li>
      Du skal ikke entre banen du har leid før avtalt leietidspunkt, ikke engang
      for å sette fra deg treningsutstyret ditt, da dette er forstyrrende for de
      som måtte leie før deg.
    </li>
    <hr />
    <li>
      Du skal forholde deg til det avtalt arealet og ikke benytte deg av mer enn
      hva du har booket.
    </li>
    <hr />
    <li>
      Du skal være ferdig ryddet og pakket ut til avtalt tid og skal forlate
      område når du har gått ned hunden din.
    </li>
    <hr />
    <li>
      Det er en stor jobb å holde område i orden så vi setter pris på at dere
      rydder opp søpla etter dere og alle skal plukke opp etter hunden sin.
    </li>
    <hr />
    <li>
      For å leie agility utstyret må du ha fullført nybegynnerkurs (både hund og
      fører)
    </li>
    <hr />
    <li>
      Om du har booket agility utstyret skal det godkjennes av oss og dere får
      tilgang til utstyret. Det skal behandles varsomt og ryddes tilbake slik du
      fant det. Informer oss om noe uheldigvis skulle bli ødelagt så vi får
      ordnet det.
    </li>
    <hr />
    <li>
      Du som booker, har ansvaret for å videreformidle denne informasjonen til
      andre som måtte delta på trening.
    </li>
    <hr />
    <li>Ellers gjelder vanlig folkeskikk.</li>
  </ul>
  <div class="spacer"></div>
</template>
<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "Privacy Policy",
});
</script>
<style scoped>
h2 {
  font-size: 1rem;
  margin-bottom: 4rem;
}
li {
  list-style: none;
  width: 40rem;
  margin: 0 auto;
  margin-bottom: 1rem;
}
hr {
  width: 20rem;
  margin: 0 auto;
  margin-bottom: 1rem;
}
.spacer {
  margin-bottom: 10rem;
}
@media screen and (max-width: 768px) {
  p {
    width: 20rem;
  }
}
</style>
